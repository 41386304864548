<template>
    <v-container class="grey lighten-5">
        <SpinnerComponent :spinning="proveedores.isLoading" text="Cargando contactos..." />
        <DataTableComponent
            :headers="headers"
            :show_loading="proveedores.isLoading"
            :items="proveedores.data"
            :total_registros="totalRegistros"
            v-models:pagina="paginacion.page"
            v-models:select="paginacion.per_page"
            @paginar="manejarPaginacionRegistros"
        >
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 16px;">
                    <v-btn icon @click.stop="cargarContactos(item)">
                        <v-icon>mdi-account-box</v-icon>
                    </v-btn>
                </div>
            </template>
        </DataTableComponent>


        <v-dialog v-model="modalAbierta" persistent max-width="800px">
            <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
                <div style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 mt-4"
                        @click.stop="modalAbierta = false" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 py-4">
                    <v-skeleton-loader
                        v-if="contactos.isLoading"
                        loadomg
                        boilerplate
                        type="article, article"
                    />
                    <v-expansion-panels>
                        <v-expansion-panel
                          v-for="contacto in contactos.data"
                          :key="contacto.id"
                        >
                          <v-expansion-panel-header>
                            <span class="font-weight-bold title" style="font-size: 18px !important; line-height: 24px !important;">{{ contacto.nombre }}</span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-divider />
                            <dl>
                                <dt class="font-weight-bold mt-4 subtitle-1">
                                    <v-icon class="mr-1" style="font-size: 20px;">mdi-text-long</v-icon>
                                    <span>Descripción:</span>
                                </dt>
                                <dd class="text-justify body-1">{{ contacto.descripcion ?? '-' }}</dd>
                                            
                                <dt class="font-weight-bold mt-8 subtitle-1">
                                    <v-icon class="mr-1">mdi-store-marker</v-icon>
                                    <span>Dirección:</span>
                                </dt>
                                <dd class="text-justify body-1">{{ contacto.direccion }}</dd>
            
                                <dt class="font-weight-bold mt-8 subtitle-1">
                                    <v-icon class="mr-1" style="font-size: 20px;">mdi-email</v-icon>
                                    <span>Correo electrónico:</span>
                                </dt>
                                <dd class="body-1">{{ contacto.correo_electronico }}</dd>
            
                                <dt class="font-weight-bold mt-8 subtitle-1">
                                    <v-icon class="mr-1" style="font-size: 20px;">mdi-phone</v-icon>
                                    <span>Teléfonos:</span>
                                </dt>
                                <dd class="body-1">{{ 
                                    contacto.telefonos.length > 0
                                    ? contacto.telefonos.map((telefono) => telefono.telefono).join(' / ')
                                    : '-' 
                                }}</dd>
                            </dl>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <div class="d-flex align-center justify-center mt-4" style="gap: 16px;">
                        <v-btn class="primary" @click.stop="modalAbierta = false">Cerrar</v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
    </v-container>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import SpinnerComponent from '@/components/utils/SpinnerComponent.vue';
import { createLoadable, createPageable, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';

export default {
    components: { SpinnerComponent, DataTableComponent },
    data: () => ({
        idFamilia: null,
        proveedores: createPageable([], 10),
        contactos: createLoadable([]),
        paginacion: {
            page: 1,
            per_page: 10,
        },
        headers: [
            { text: 'Nombre', align: 'center', sortable: false, value: 'nombre' },
            { text: 'Nombre comercial', align: 'center', sortable: false, value: 'nombre_comercial' },
            { text: 'Acciones', align: 'center', sortable: false, value: 'acciones' },
        ],
        modalAbierta: false,
        proveedorActivo: null,
    }),
    computed: {
        totalRegistros() {
            return this.proveedores.pagination.total_rows;
        },
    },
    methods: {
        async cargarProveedores() {
            const params = {
                pagination: true,
                ...this.paginacion,
            }
            togglePageable(this.proveedores);
            const { data, headers } = await this.services.CmContactosProveedor.cargarProveedoresFamilia(this.$route.params.id_familia, params);
            setPageableResponse(this.proveedores, data, headers);
        },
        async cargarContactos(proveedor) {
            this.contactos = createLoadable([]);
            this.proveedorActivo = proveedor;
            this.modalAbierta = true;

            toggleLoadable(this.contactos);
            const { data } = await this.services.CmContactosProveedor.cargarContactosProveedor(this.$route.params.id_familia, proveedor.id);
            setLoadableResponse(this.contactos, data, { showAlertOnSuccess: true });
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginacion  = {
                page: pagina,
                per_page: cantidad_por_pagina,
            };
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarProveedores();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.idFamilia = this.$route.params.id_familia;
    },
}
</script>